import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.handleFormError();

    this.submitButton = this.element.querySelector('button[type="submit"]');
    if (this.submitButton) this.oldSubmitText = this.submitButton.innerText;

    this.element.addEventListener('turbo:submit-start', this.disableSubmit);
    this.element.addEventListener('turbo:submit-end', this.handleSubmit);
    this.element.addEventListener('turbo:submit-end', this.handleFormError);
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-start', this.disableSubmit);
    this.element.removeEventListener('turbo:submit-end', this.handleSubmit);
    this.element.removeEventListener('turbo:submit-end', this.handleFormError);
  }

  handleFormError = () => {
    // Selecting the error class when the input tag does not include the form-error class.
    // For example date pickers and select elements etc.
    const formErrorEl =
      this.element.querySelector('input.form-error') ||
      this.element.querySelector('.form-group-error');

    if (!formErrorEl) return;

    formErrorEl.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    });

    if (formErrorEl.tagName.toLowerCase() !== 'input') return;

    setTimeout(() => {
      formErrorEl.focus();
    }, 10);
  };

  handleSubmit = (e) => {
    if (this.oldSubmitText) this.enableSubmit();

    if (e.detail.success) {
      this.dispatch('submit-success');
    }
  };

  disableSubmit = () => {
    if (!this.submitButton) return;

    const dataset = this.submitButton?.dataset;
    if (!dataset) return;

    const replacement = dataset.turboDisableWith || dataset.disableWith;

    if (!replacement) return;
    this.submitButton.innerHTML = replacement;
  };

  enableSubmit = () => {
    this.submitButton = this.oldSubmitText;
  };

  getTopOffset(controlEl) {
    const labelOffset = 80;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
